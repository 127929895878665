<template>
  <div class="flex justify-content-start curator-filters">
    <custom-input
      :delay="800"
      name="curator-passings-sort"
      label="Имя"
      :inputWidth="100"
      cleanable
      v-model="searchParams.nameField"
    />
    <custom-input
      :delay="800"
      name="curator-passings-sort"
      label="Email"
      :inputWidth="100"
      cleanable
      v-model="searchParams.emailField"
    />
  </div>
  <div v-if="curators">
    <ag-grid-vue
      :columnDefs="columnDefs.value"
      :rowData="rowData"
      :rowHeight="100"
      :headerHeight="90"
      :enableCellTextSelection="true"
      :ensureDomOrder="true"
      :pagination="true"
      :unSortIcon="true"
      :paginationPageSize="countOnPage"
      :defaultColDef="defaultColDef"
      :suppressRowHoverHighlight="true"
      :suppressPaginationPanel="true"
      :suppressRowClickSelection="true"
      :suppressRowTransform="true"
      style="width: 100%"
      domLayout="autoHeight"
      @grid-ready="onGridReady"
    ></ag-grid-vue>

    <v-pagination
      v-if="gridApi"
      v-model="page"
      @update:modelValue="changePage"
      :pages="gridApi.paginationGetTotalPages()"
      :range-size="1"
      active-color="transparent"
    />

    <div
      v-if="gridApi && gridApi.paginationGetTotalPages()"
      class="pagination-info"
    >
      <p>
        Показаны строки с {{ countOnPage * (page - 1) + 1 }} по
        {{ gridApi.paginationProxy.bottomDisplayedRowIndex + 1 }} из
        {{ gridApi.paginationGetRowCount() }}
      </p>
      <p>
        Страница {{ gridApi.paginationGetCurrentPage() + 1 }} из
        {{ gridApi.paginationGetTotalPages() }}
      </p>
    </div>
    <div class="pagination-info" v-else>Нет результатов</div>
  </div>
  <div v-else class="table-preloader">
    <preloader />
  </div>
</template>

<script>
import VPagination from "@hennge/vue3-pagination";
import "ag-grid-community/dist/styles/ag-grid.css";
import { AgGridVue } from "ag-grid-vue3";
import { reactive, ref } from "@vue/reactivity";
import UserGroupsRenderer from "@/components/Tables/CellRenderers/UserGroupsRenderer.vue";
import ImageRenderer from "./CellRenderers/ImageRenderer.vue";
import TextLinksRenderer from "./CellRenderers/TextLinksRenderer.vue";
import TextListRenderer from "./CellRenderers/TextListRenderer.vue";
import { useStore } from "vuex";
import { computed, onBeforeMount, watch } from "@vue/runtime-core";
import { useRoute } from "vue-router";
import Preloader from "../Technical/Preloader.vue";
import CustomInput from "../Forms/Fields/CustomInput.vue";
import EditRenderer from "./CellRenderers/EditRenderer";

export default {
  name: "curator-table",
  components: {
    VPagination,
    AgGridVue,
    // eslint-disable-next-line vue/no-unused-components
    ImageRenderer,
    // eslint-disable-next-line vue/no-unused-components
    TextLinksRenderer,
    // eslint-disable-next-line vue/no-unused-components
    TextListRenderer,
    Preloader,
    CustomInput,
    // eslint-disable-next-line vue/no-unused-components
    EditRenderer,
    UserGroupsRenderer,
  },
  setup() {
    const store = useStore(),
      route = useRoute(),
      gridApi = ref(null),
      columnApi = ref(null);

    const onGridReady = (params) => {
      gridApi.value = params.api;
      columnApi.value = params.columnApi;
    };
    const countOnPage = ref(15),
      page = ref(1),
      isPreloader = ref(false),
      searchParams = reactive({
        nameField: "",
        emailField: "",
      });
    const curators = computed(() => store.state.curator.curators),
      rowData = computed(() => {
        return curators.value.map((curator) => {
          return {
            id: curator.id,
            name: curator.name,
            email: curator.email,
            photo: curator.image,
            groups: curator.groups,
            passing_count: curator.passing_count,
            edit: curator.id,
          };
        });
      });

    const columnDefs = reactive({
      value: [
        {
          field: "id",
          hide: true,
        },
        {
          field: "photo",
          headerName: "Фото",
          maxWidth: 100,
          flex: 0,
          cellRenderer: "ImageRenderer",
        },
        {
          field: "name",
          headerName: "Имя",
          wrapText: true,
          minWidth: 305,
        },
        {
          field: "email",
          headerName: "Email",
          wrapText: true,
          minWidth: 255,
        },
        {
          field: "groups",
          minWidth: 255,
          headerName: "Группы",
          cellRenderer: "UserGroupsRenderer",
        },
        {
          field: "passing_count",
          minWidth: 255,
          headerName: "",
          cellRenderer: "TextListRenderer",
        },
        {
          field: "edit",
          width: 70,
          headerName: "",
          flex: 0,
          cellRenderer: "EditRenderer",
          cellRendererParams: {
            source: "staff",
            end: "edit",
          },
        },
      ],
    });
    const defaultColDef = {
      suppressMovable: true,
      flex: 1,
      autoHeight: true,
    };

    onBeforeMount(() => {
      store.dispatch("curator/getCurators");
    });

    const changePage = (page) => {
      gridApi.value.paginationGoToPage(page - 1);
    };

    watch(searchParams, () => {
      if (
        searchParams.nameField.length >= 3 ||
        searchParams.emailField.length >= 3
      ) {
        gridApi.value.setQuickFilter(
          `${
            searchParams.nameField.length >= 3 ? searchParams.nameField : ""
          } ${
            searchParams.emailField.length >= 3 ? searchParams.emailField : ""
          }`
        );
      } else {
        gridApi.value.setQuickFilter(null);
      }
      page.value = 1;
    });

    watch(countOnPage, () => {
      gridApi.value.paginationSetPageSize(countOnPage.value);
      page.value = 1;
    });

    return {
      route,
      countOnPage,
      isPreloader,
      page,
      changePage,
      curators,
      searchParams,

      columnDefs,
      rowData,
      defaultColDef,
      gridApi,
      onGridReady,
      columnApi,
    };
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  min-width: 350px;
}

@media (max-width: 1080px) {
  .form-group {
    min-width: 100%;
  }
  .curator-filters {
    flex-direction: column;
  }
}
</style>
