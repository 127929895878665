<template>
  <div v-for="(group, id) of Object.values(params.value)" :key="id">
    <div v-if="params.source === 'group'">
      <router-link :to="`/group/${group.id}`">{{
          group.name
        }}
      </router-link>
    </div>
  </div>
</template>

<style lang="scss" scoped>
a {
  width: 100%;
  display: block;

  &:hover {
    color: var(--hover-color);
  }
}
</style>