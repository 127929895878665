<template>
  <div class="view-container">
    <div class="card curator-table">
      <div class="card-header card-header-divider">
        <div class="card-title">Кураторы</div>
      </div>
      <div class="card-body">
        <curator-table />
      </div>
    </div>
  </div>
</template>

<script>
import CuratorTable from "../../components/Tables/CuratorTable.vue";
export default {
  components: { CuratorTable },
  name: "curator-page",
};
</script>

<style>
</style>